import { observable, flow, action } from 'mobx'
import { CommonStore } from 'stores/common'
import { storeApi } from 'api'

export const StoreStore = () => {
  const clearPaging = action(function () {
    const endPage = { isLoading: false, nextCursor: null, hasNextPage: false }
    this.isLoading = endPage.isLoading
    this.nextCursor = endPage.nextCursor
    this.hasNextPage = endPage.hasNextPage
    return endPage
  })

  const clear = action(function () {
    this.items = []
    return { ...this.clearPaging(), items: [] }
  })

  const findProducts = flow(function* (params) {
    const {
      data: { products }
    } = yield this.api.graphql(`
      {
        products(first: 15, sortKey: CREATED_AT, reverse: true, query: "tag:${params.tag}") {
          edges {
            node {
              title
              tags
              handle
              id
              images(first: 5) {
                edges {
                  node {
                    originalSrc
                  }
                }
              }
              variants(first: 25) {
                edges {
                  node {
                    id
                    selectedOptions {
                      name
                      value
                    }
                    price {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
    this.items = products
    return products
  })

  const findProductsByCollection = flow(function* (params) {
    if (!params.collection) return this.clear()
    const response = yield this.api.graphql(`
      {
        collections(first: 1, query: "${params.collection}") {
          edges {
            node {
              products(first: ${params.numProducts || 8}) {
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                }
                edges {
                  cursor
                  node {
                    title
                    tags
                    handle
                    id
                    images(first: 5) {
                      edges {
                        node {
                          originalSrc
                        }
                      }
                    }
                    variants(first: 25) {
                      edges {
                        node {
                          id
                          selectedOptions {
                            name
                            value
                          }
                          price {
                            amount
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
    const collections = response.data?.collections
    if (!collections) return this.clearPaging()

    const products = collections?.edges?.[0]?.node?.products
    const edges = products?.edges ?? []
    const pageInfo = products?.pageInfo ?? {}
    const items = { edges }
    const cursor = edges[edges?.length - 1]?.cursor ?? null
    const hasNextPage = pageInfo?.hasNextPage ?? false

    this.cursor = cursor
    this.items = items
    this.hasNextPage = hasNextPage
    return { items, cursor, hasNextPage }
  })

  const findNextProductsByCollection = flow(function* (params) {
    if (!params.collection) return this.clear()
    else if (!params.cursor) return this.clearPaging()

    this.isLoading = true
    const response = yield this.api.graphql(`
    {
      collections(first: 1, query: "${params.collection}") {
        edges {
          node {
            products(first: ${params.numProducts ?? 8}, after: "${params.cursor}") {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                cursor
                node {
                  title
                  tags
                  handle
                  id
                  images(first: 5) {
                    edges {
                      node {
                        originalSrc
                      }
                    }
                  }
                  variants(first: 25) {
                    edges {
                      node {
                        id
                        selectedOptions {
                          name
                          value
                        }
                        price {
                          amount 
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `)
    const collections = response.data?.collections
    if (!collections || !collections?.edges?.length) return this.clearPaging()

    const { edges, pageInfo } = collections?.edges[0].node.products
    this.cursor = edges[edges.length - 1]?.cursor
    this.items.edges.push(...edges)
    this.hasNextPage = pageInfo.hasNextPage
    this.isLoading = false
    return {
      items: this.items,
      nextCursor: edges[edges.length - 1]?.cursor,
      hasNextPage: pageInfo.hasNextPage
    }
  })

  return observable({
    ...CommonStore,
    api: storeApi,
    findProducts,
    findProductsByCollection,
    findNextProductsByCollection,
    cursor: null,
    hasNextPage: true,
    clearPaging,
    clear
  })
}

StoreStore.cacheKey = 'StoreStore'

export default StoreStore
