import ExternalScriptLoader from './external-script-loader'
class Donately extends ExternalScriptLoader {
  accountId = process.env.DONATELY_ACCOUNT_ID
  donatelyVersion = '2021-02-19'
  scriptUrl = 'https://cdn.donately.com/core/5.6/donately.min.js'

  async fetchCampaign(campaignId) {
    const url = `https://api.donately.com/v2/campaigns/${campaignId}?account_id=${this.accountId}`
    const res = await fetch(url, {
      headers: {
        'Donately-Version': this.donatelyVersion
      }
    })
    const json = await res.json()
    if (!res.ok) {
      throw json
    }
    return json
  }
}

export default new Donately()
