import React, { useEffect, useRef, useState } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { parsePadding, THEME } from 'utils'
import CloseIcon from 'icons/Close'

/**
 * This component is used to render a modal.
 * @param {string} title - The title of the modal.
 * @param {boolean} isOpen - Whether or not the modal is open.
 * @param {boolean} unmount - Whether or not the modal should unmount from the DOM when closed.
 * @param {function} close - The function to close the modal.
 * @param {node} children - The children of the modal.
 * @param {string} maxWidth - The max width of the modal.
 * @param {string} padding - The padding of the modal.
 */

const Modal = ({ title = '', isOpen = false, unmount = true, close, children, maxWidth, padding }) => {
  const modalRef = useRef()
  const [mount, setMount] = useState(false)

  useEffect(() => {
    const handleKeyDown = ({ key, code }) => {
      if (key === 'Escape' && code === 'Escape') close()
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(modalRef.current, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true
            }

            el = el.parentElement
          }
        }
      })
      return () => {
        clearAllBodyScrollLocks(modalRef.current)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollTo(0, 0)
    }
    const timeout = setTimeout(() => setMount(isOpen), 300)
    return () => clearTimeout(timeout)
  }, [isOpen])

  const paddingSides = parsePadding(padding)

  return (
    <>
      {(!unmount || mount) && (
        <div
          className={`modalBackdrop block ${isOpen ? 'appear' : 'disappear'} ${
            !unmount && !mount ? 'hidden' : 'block'
          }`}
          ref={modalRef}
        >
          <div className='modalForeground'>
            <div className='modalContent'>
              <div className='modalHeader'>
                <h1>{title}</h1>
                <div onClick={close} className='closeButton'>
                  <CloseIcon />
                </div>
              </div>
              <div className='modalBody'>{children}</div>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        @-webkit-keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-webkit-keyframes fadeOut {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fadeOut {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        .modalBackdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          z-index: 10;
        }
        .modalForeground {
          position: fixed;
          background: white;
          width: 100%;
          max-width: ${maxWidth || '100%'};
          height: auto;
          max-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          overflow-y: scroll;
          padding-right: ${paddingSides.right ?? '1rem'};
          padding-bottom: ${paddingSides.bottom ?? '1rem'};
          padding-left: ${paddingSides.left ?? '1rem'};
        }
        .modalContent {
          position: relative;
          width: 100%;
          max-height: 100%;
        }
        .modalHeader {
          position: sticky;
          top: 0;
          z-index: 10;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          padding-top: ${padding.top ?? '1rem'};
          padding-bottom: ${paddingSides.top ?? '1rem'};
        }
        .modalHeader h1 {
          font-family: 'Helvetica Neue';
        }
        .modalBody {
          margin-top: 1rem;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .modalForeground {
            max-width: ${maxWidth || '900px'};
            max-height: 90%;
            padding-right: ${paddingSides.right ?? '3rem'};
            padding-bottom: ${paddingSides.bottom ?? '3rem'};
            padding-left: ${paddingSides.left ?? '3rem'};
          }
          .modalHeader {
            padding-top: ${paddingSides.top ?? '3rem'};
            padding-bottom: ${paddingSides.top ?? '3rem'};
          }
        }
        .block {
          display: block;
        }
        .hidden {
          display: none;
        }
        .appear {
          -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
        .disappear {
          -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
          -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
        .closeButton {
          width: auto;
          padding: 1rem;
          cursor: pointer;
        }
      `}</style>
    </>
  )
}

export default Modal
